body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.se-pre-con {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #000;
}

#app {
  background-image: url("../assets/img/fundo.jpg");
  background-color: black;
  background-repeat: repeat;
  color: white;
}

.description {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-align: justify;
  color: white;
}

#header {
  width: 100%;
}

#gearlogo {
  width: 80%;
  max-height: 100%;
}

/* Configs for scrollbar */
::-webkit-scrollbar {
  width: 8px;
  background-color: rgb(0, 0, 0);
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.849);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.849);
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.26);
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 6px rgba(73, 73, 73, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(73, 73, 73, 0.3);
  background-color: #555;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* /Configs for scrollbar */
