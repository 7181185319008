.team {
  height: 300px;
  width: 100%;
  position: relative;

  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.5);

  padding: 0;
  border: 2px solid white;
  border-radius: 20px;
}

.team-container {
  width: 500px;
  padding: 10px 10px 10px 10px;
}

.info {
  border-radius: 19px;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 300ms ease;
}

i.fa {
  color: white;
  opacity: 0.8;
}

i.fa:hover {
  opacity: 1;
}

.team:hover .info {
  opacity: 1;
}

.team > img {
  max-height: 100%;
  max-width: 100%;

  width: 70%;
  height: auto;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  margin: auto;
}
